const Shipping = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50784 2.6001C8.42745 2.6001 7.40015 3.4223 7.40015 4.61548V7.3999H4.8002C4.52213 7.3999 4.25662 7.51568 4.0674 7.71945L0.467404 11.5963C0.295649 11.7813 0.200195 12.0244 0.200195 12.2768V16.7999C0.200195 17.3522 0.647911 17.7999 1.2002 17.7999H2.62037C2.60723 17.8646 2.60034 17.9315 2.60034 18C2.60034 19.8777 4.1226 21.4 6.00034 21.4C7.87809 21.4 9.40034 19.8777 9.40034 18C9.40034 17.9302 9.39318 17.862 9.37956 17.7962C9.4222 17.7988 9.46498 17.8001 9.50784 17.8001H15.8203C15.8072 17.8647 15.8003 17.9315 15.8003 18C15.8003 19.8777 17.3225 21.4 19.2003 21.4C21.078 21.4 22.6003 19.8777 22.6003 18C22.6003 17.87 22.5755 17.7458 22.5303 17.6319C23.2494 17.3331 23.8001 16.6584 23.8001 15.7847V4.61548C23.8001 3.4223 22.7728 2.6001 21.6925 2.6001H9.50784ZM20.6203 17.8001H17.7803C17.7934 17.8647 17.8003 17.9315 17.8003 18C17.8003 18.7732 18.4271 19.4 19.2003 19.4C19.9735 19.4 20.6003 18.7732 20.6003 18C20.6003 17.9315 20.6072 17.8647 20.6203 17.8001ZM4.60034 18C4.60034 17.9315 4.59345 17.8646 4.58032 17.7999H7.42037C7.40723 17.8646 7.40034 17.9315 7.40034 18C7.40034 18.7732 6.77352 19.4 6.00034 19.4C5.22717 19.4 4.60034 18.7732 4.60034 18ZM7.40015 9.3999V15.7847L7.4002 15.7989V15.7999H2.2002V12.6695L5.23626 9.3999H7.40015ZM9.4002 15.7655L9.4003 15.7655C9.41515 15.7792 9.45055 15.8001 9.50784 15.8001H21.6925C21.7497 15.8001 21.7851 15.7792 21.8 15.7655L21.8001 15.7654V4.63479L21.8 4.63465C21.7851 4.62103 21.7497 4.6001 21.6925 4.6001H9.50784C9.45056 4.6001 9.41516 4.62103 9.4003 4.63465L9.40015 4.63479V8.38994L9.4002 8.3999V15.7655Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default Shipping
